<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/gywm.png" alt="" />
      <div class="jsfnB1">关于然茜</div>
      <div class="jsfnC1">ABOUT RANCII</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">能力体系</div>
        <div class="product">POWER SYSTEM</div>
      </div>
      <div class="nltx">
        &emsp;&emsp;然茜科技依托济南高新技术产业开发区的重要支撑园区——齐鲁软件园，拥有得天独厚的发展优势。结合公司自身的技术实力和软件园区带来的人才优势，经过公司多年来不断的积累沉淀，逐渐形成了角逐科技市场的核心竞争力。
      </div>
      <div>
        <div class="cpszh">四大体系</div>
        <div class="product">THE BIG FOUR SYSTEM</div>
      </div>
      <div class="bodyCon1">
        <div><img class="gywmn1" src="@/assets/gywmn1.png" alt="" /></div>
        <div>
          <div class="bodyConFont1a">
            1、懂行业，深入不同行业沉淀行业经验，助力行业科技赋能
          </div>
          <div class="bodyConFont1b">
            &emsp;&emsp;公司经过多年的发展沉淀，公司在智能交通、智慧环保、金融科技、大健康、新零售等各个行业领
            域均拥有行业资深经验，积累了行业高端的核心技术、行业差异化能力和行业数据洞察。
            基于对行业的深度理解，公司服务于大量优质客户，形成数据智能商业闭环，公司也总结自身的行业经验，并迭
            加贴心服务，成为更多行业客户合伙人。
          </div>
        </div>
      </div>
      <div class="bodyCon2">
        <div>
          <div class="bodyConFont2a">2、有技术，拥有持续开发迭代技术</div>
          <div class="bodyConFont2b">
            &emsp;&emsp;公司依托软件园优势，为公司积累了人工智能、大数据等一系列核心技术。公司在研发科技创新的同时，能够对
            前沿科技跟踪学习，并应用到涉猎行业中，通过和各大企业软件开发合作，为客户交付软件开发服务及先进技术提供解决方案，
            实现应用和产品的落地。
          </div>
        </div>
        <div><img class="gywmn2" src="@/assets/gywmn2.png" alt="" /></div>
      </div>
      <div class="bodyCon3">
        <div><img class="gywmn1" src="@/assets/gywmn3.png" alt="" /></div>
        <div>
          <div class="bodyConFont1a">
            3、能落地，具备高难度、大型项目实施管理能力
          </div>
          <div class="bodyConFont1b">
            &emsp;&emsp;经过多年的技术积累、大型现代化项目管理经验积累，公司具备从咨询设计到软件开发、软硬件总集成、运营维护，
            再到大数据、云计算、新一代人工智能的大型工程总集成实施能力，擅长承接诸如海量多源异构数据协同互联、跨系
            统资源高效整合、业务高并发处理等高难度、个性化需求，保障客户数字化转型的成功落地实施。已成功交付多个千万
            级具有重大社会影响力的软硬件总集成项目。
          </div>
        </div>
      </div>
      <div class="bodyCon4">
        <div>
          <div class="bodyConFont2a">
            4、重服务，提供一流技术外包服务及软件开发
          </div>
          <div class="bodyConFont2b">
            &emsp;&emsp;研发团队，还拥有规模大、技术硬、经验多的外包团队，在北京、上海、深圳、成都等地都拥有不同行业内的
            外包服务团队。此外，公司还在不断扩展团队规模，持续吸收新鲜血液。未来公司还要打造一张与国内各大知名公司所合作的生态圈，
            加深公司和各行各业的深度融合。公司使用行业领先技术以及先进的管理经验完善团队，为客户提供更优质的服务。
            目前公司已经为浪潮集团、中创软件、中建八局等公司建立长期合作。
          </div>
        </div>
        <div><img class="gywmn2" src="@/assets/gywmn4.png" alt="" /></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.nltx {
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 1px;
  margin: 20px 32px 20px 32px;
}
.bodyCon1 {
  width: 1138px;
  height: 293px;
  background: rgba(24, 63, 133, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 20px 0px 20px;
  margin: 43px 32px 24px 32px;
  display: flex;
}
.gywmn1 {
  margin: 20px 16px;
}
.bodyConFont1a {
  margin: 20px 0;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1px;
}
.bodyConFont1b {
  margin: 0 16px 20px 0;
  font-size: 18px;
  color: #7d7d7d;
  line-height: 40px;
  letter-spacing: 1px;
}
.bodyCon2 {
  width: 1138px;
  height: 293px;
  background: rgba(24, 63, 133, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 20px 0px 20px;
  margin: 0 32px 24px 32px;
  display: flex;
}
.bodyConFont2a {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1px;
  margin: 20px 16px;
}
.bodyConFont2b {
  font-size: 18px;
  color: #7d7d7d;
  line-height: 40px;
  letter-spacing: 1px;
  margin: 0 0 20px 16px;
}
.gywmn2 {
  margin: 20px 16px;
}
.bodyCon3 {
  width: 1138px;
  height: 293px;
  background: rgba(24, 63, 133, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 20px 0px 20px;
  margin: 0 32px 24px 32px;
  display: flex;
}
.bodyCon4 {
  width: 1138px;
  height: 293px;
  background: rgba(24, 63, 133, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 20px 0px 20px;
  margin: 0 32px 0 32px;
  display: flex;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnD1 {
  position: absolute;
  top: 360px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 40px;
  letter-spacing: 1px;
}
</style>
